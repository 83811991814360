/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Hello!"), "\n", React.createElement(_components.p, null, "How are you? I hope you're doing well. I'm happy that you've made it this far. For me, this is a great opportunity to share my service with those who need and desire it. I provide both in-person and online assistance to people all around the world."), "\n", React.createElement(_components.p, null, "A few years ago, I embarked on a journey of self-healing due to a symptom that was preventing me from living in peace. Today, the symptom has healed, my reality has changed, and my perspective on life has gained meaning. Of course, my journey continues, as there are still many realities within me that need healing, and the enthusiasm to improve myself remains intact. Furthermore, this path has expanded. Today, I support individuals who wish to heal and understand the profound message that a physical, emotional, relational, or economic symptom brings to their lives. I assist those brave people who seek to bring fulfillment to their human experience."), "\n", React.createElement(_components.p, null, "It is possible to transform, heal, and decode any kind of pain in the body, allergies, phobias, panic attacks, anxiety, and even diagnosed symptoms such as celiac disease, diabetes, infertility, autism, Asperger's, arthritis, and more. I also accompany individuals dealing with economic or relationship issues, also as those who desire a partner, children, a home, etc. In summary, any problem or pain in your life that prevents you from experiencing fulfillment can be addressed in a consultation. I am a certified consultant in Bioemotional Decoding and Conscious Bioexistence, trained by Humano Puente School (", React.createElement(_components.a, {
    href: "http://www.humanopuente.com.ar"
  }, "www.humanopuente.com.ar"), "). This spiritual approach, with biological foundations, emphasizes the reprogramming of the individual and family's unconscious mind, considering that the unconscious (soul) is programmed by biological success and biological failure and that each symptom represents an unresolved painful memory. Symptoms arise due to evolution and biological logic, as well as stored and suppressed emotions. We are the expression of the content, and the good news is that we can modify it at a deeper level than the conscious mind—the unconscious mind!"), "\n", React.createElement(_components.p, null, "If you are interested in transforming something in your life and would like to learn more about my approach or only want to ask about a specific symptom, please feel free to contact me :) (Via WhatsApp or email)."), "\n", React.createElement(_components.p, null, "I will accompany you to transit in order and coherence the journey of your healing."), "\n", React.createElement(_components.p, null, "Sending you a big hug and"), "\n", React.createElement(_components.p, null, "Thank you very much! :)"), "\n", React.createElement(_components.h3, null, "¡Thank you very much! :)"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
